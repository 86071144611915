import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from "react-multi-carousel";
import headerImg from "../assets/img/header-img.svg";
import homeCube from "../assets/img/homeCube.png";
import homeCube2 from "../assets/img/homeCube2.png";
import homeCube3 from "../assets/img/homeCube3.png";
import avatarImg from "../assets/img/avatar.png";
import "react-multi-carousel/lib/styles.css";
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png";

export const Features = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="feature" id="features">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="feature-bx wow zoomIn">
              <h1>Features</h1>
              <br />
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.<br></br> Lorem Ipsum has been the industry's standard
                dummy text.
              </p> */}
              <Carousel
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme feature-slider"
              >
                <div className="item1">
                  <img src={headerImg} alt="Image" />
                  <h5>Awesome Looks</h5>
                  <p>
                    Dark themed with elements of space. Yet still featuring
                    generic colors.
                  </p>
                </div>
                <div className="item2">
                  <img src={avatarImg} alt="Image" />
                  <h5>Create For Fun</h5>
                  <p>
                    Powered by a super app using augmented reality for
                    experiences beyond this world.
                  </p>
                </div>
                <div className="item3">
                  <img src={homeCube3} alt="Image" />
                  <h5>World's First</h5>
                  <p>
                    Everyone is excited for the World's first Holographic Cube.
                  </p>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  );
};
