import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={5}>
            <img src={logo} alt="Logo" />
            <p>
              Cubelox started as an experiment on our existing cubes turned out
              to be an awesome experience that everyone loved. Hence, We are
              presenting the world Cubelox - World’s First Holographic Cube.
            </p>
            <div className="social-icon">
              {/* <a href="#">
                <img src={navIcon1} alt="Icon" />
              </a> */}
              <a
                href="https://www.facebook.com/Cubelox-105577672554981"
                target="_blank"
              >
                <img src={navIcon2} alt="Icon" />
              </a>
              <a
                href="https://instagram.com/cubelox_official?igshid=MzRlODBiNWFlZA=="
                target="_blank"
              >
                <img src={navIcon3} alt="Icon" />
              </a>
            </div>
            <p>
              Company: Ghar Par Ishnam Business Private Limited <br />
              Address: Sec-5 Rohini New Delhi -110085
              <br />
              <br /> Contact: +91-9310533041
            </p>
            <p>Copyright 2023. All Rights Reserved</p>
          </Col>

          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="terms-condition">
              <a
                href="https://assets-cubelox.s3.ap-south-1.amazonaws.com/terms.html"
                target="_blank"
              >
                <p>Terms and Conditions</p>
              </a>
              <a
                href="https://assets-cubelox.s3.ap-south-1.amazonaws.com/privacy.html"
                target="_blank"
              >
                <p>Privacy Policy</p>
              </a>
              <a
                href="https://assets-cubelox.s3.ap-south-1.amazonaws.com/shipping.html"
                target="_blank"
              >
                <p>Shipping Policy</p>
              </a>
              <a
                href="https://assets-cubelox.s3.ap-south-1.amazonaws.com/return.html"
                target="_blank"
              >
                <p>Return Policy</p>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
